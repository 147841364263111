<template>
  <div class="momai-main-layout">
    <van-nav-bar
      v-show="layoutState.showNav"
      :title="layoutState.title"
      left-text="返回"
      left-arrow
      fixed
      right-text="主页"
      @click-left="goBack"
      @click-right="goHome"
    />
    <div class="main">
      <router-view
        v-slot="{ Component }"
        :class="[
          'momai-main-layout',
          {
            'with-nav': layoutState.showNav,
          },
          { 'with-bottom': layoutState.showBottom },
        ]"
      >
        <component :is="Component" />
      </router-view>
    </div>
    <van-tabbar
      v-model="active"
      v-show="layoutState.showBottom"
      route
      class="rounded"
    >
      <van-tabbar-item to="/home">
          <span>首页</span>
          <template #icon="props">
            <svg-icon :icon-class="props.active ? icons[0].active : icons[0].noActive" className="svg-home-tab"/>
          </template>
      </van-tabbar-item>

      <van-tabbar-item to="/study">
        <span>{{Number(companyId) === 999 ? '学习圈' : '企业圈'}}</span>
        <template  #icon="props">
          <svg-icon :icon-class="props.active ? icons[1].active : icons[1].noActive" className="svg-home-tab"/>
        </template>
      </van-tabbar-item>

      <van-tabbar-item to="/daily">
        <div class="card">
          <img src="@/assets/imgs/icon-dk.png">
        </div>
      </van-tabbar-item>

      <van-tabbar-item to="/findtea" >
        <span>找师傅</span>
        <template  #icon="props">
          <svg-icon :icon-class="props.active ? icons[2].active : icons[2].noActive" className="svg-home-tab"/>
        </template>
      </van-tabbar-item>

      <van-tabbar-item  to="/user" :dot="isUserHasUreadMessage">
        <span>我的</span>
        <template  #icon="props">
          <svg-icon :icon-class="props.active ? icons[3].active : icons[3].noActive" className="svg-home-tab"/>
        </template>
      </van-tabbar-item>

    </van-tabbar>
  </div>
</template>
<script>
import { ref } from 'vue'
import { layoutState, goBack } from '@/states/layout'
import router from '@/router'
import { api } from '@/api/useAxios'
import { getToken } from '@/utils/auth'
import MomaiTitle from './components/MomaiTitle'
import { getCompanyId } from '@/utils/auth';

export default {
  name: 'Main',
  components: {
    MomaiTitle
  },
  setup: () => {
    const active = ref(1)
    const isUserHasUreadMessage = ref(false)
    const icons =  Object.freeze([
      { active: 'home-active', noActive: 'home'},
      { active: 'study-active', noActive: 'study'},
      { active: 'tea-active', noActive: 'tea' },
      { active: 'mine-active', noActive: 'mine'}
      ])

    if (getToken()) {
      api.get('/sysuser/hasUnreadMessage').then((r) => {
        isUserHasUreadMessage.value = r
      })
    }
    const companyId = getCompanyId()


    return {
      active,
      layoutState,
      isUserHasUreadMessage,
      goBack,
      goHome: () => {
        router.push('/')
      },
      icons,
      companyId
    }
  }
}
</script>

<style lang="less" scoped>
.momai-main-layout {
  /*position: absolute;*/
  /*width: 100vw;
  */

  //&.with-bottom {
  //   padding-bottom: @tabbar-height;
  //}
  :deep(.van-nav-bar) {
    background-color: rgb(253, 205, 0)
  }
  :deep(.van-nav-bar__title) {
    color: #000;
  }
  :deep(.van-nav-bar__text) {
    color: #000;
  }
  :deep(.van-icon-arrow-left::before) {
    content: '\F008';
    color: #000;
  }

  &.with-nav {
     padding-top: @nav-bar-height;
  }


  .card {
    position: absolute;
    width: 4rem;
    height: 4rem;
    background-color: @white;
    left: 50%;
    margin-left: -1.75rem;
    border-radius: 50%;
    bottom: 0.75rem;
    z-index: 9999;
    & img {
      width: 3.36rem;
      height: 3.36rem;
      position: absolute;
      top: 0.75rem;
      left: 0.35rem
    }
  }

  /deep/ .van-tabbar {
    height: 3.64rem;
    z-index: 1000;
  }

  /deep/ .van-tabbar-item__text {
    margin-top: 0.1rem;
  }
}
</style>
